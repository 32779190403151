var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.updateUser())}}},[_c('v-row',[_c('v-col',{staticClass:"text-start"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.profile.back'))+" ")])])],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto"}},[_c('kurcc-user-options')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pages.profile.editProfile'))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.profile.firstName')},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.profile.lastName')},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.profile.email')},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email (Retype)","rules":"required_if:email|confirmed:email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.profile.emailRetype')},model:{value:(_vm.user.confirm_email),callback:function ($$v) {_vm.$set(_vm.user, "confirm_email", $$v)},expression:"user.confirm_email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":"min:6|max:40","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.profile.newPassword'),"type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"New Password (Retype)","rules":"required_if:password|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.profile.newPasswordRetype'),"type":"password"},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.reset()}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.reset'))+" ")]),_c('v-btn',{attrs:{"disabled":invalid,"loading":_vm.submittingData,"text":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.update'))+" ")])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }